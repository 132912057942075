import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from './signin/signin.component';
import { ClothsListComponent } from './cloths/cloths-list/cloths-list.component';
import { ClothComponent } from './cloths/cloth/cloth.component';
import { ClothEditorComponent } from './cloths/cloth-editor/cloth-editor.component';
import { AccessoriesListComponent } from './accessories/accessories-list/accessories-list.component';
import { AccessoryComponent } from './accessories/accessory/accessory.component';
import { AccessoryEditorComponent } from './accessories/accessory-editor/accessory-editor.component';
import { ModelsListComponent } from './models/models-list/models-list.component';
import { ModelComponent } from './models/model/model.component';
import { ModelEditorComponent } from './models/model-editor/model-editor.component';
import { UnclassifiedProductListComponent } from './unclassifiedProducts/unclassified-product-list/unclassified-product-list.component';
import { UnclassifiedProductComponent } from './unclassifiedProducts/unclassifiedProduct/unclassified.product.component';
import { UnclassifiedProductEditorComponent } from './unclassifiedProducts/unclassified-product-editor/unclassified-product-editor.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserComponent } from './users/user/user.component';
import { UserEditorComponent } from './users/user-editor/user-editor.component';
import { SettingsComponent } from './settings/settings.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderComponent } from './orders/order/order.component';

const types = [{
  basePath: 'cloths',
  listComponent: ClothsListComponent,
  editorComponent: ClothEditorComponent,
  viewComponent: ClothComponent
}, {
  basePath: 'accessories',
  listComponent: AccessoriesListComponent,
  editorComponent: AccessoryEditorComponent,
  viewComponent: AccessoryComponent
}, {
  basePath: 'models',
  listComponent: ModelsListComponent,
  editorComponent: ModelEditorComponent,
  viewComponent: ModelComponent
}, {
  basePath: 'unclassifiedProducts',
  listComponent: UnclassifiedProductListComponent,
  editorComponent: UnclassifiedProductEditorComponent,
  viewComponent: UnclassifiedProductComponent
}, {
  basePath: 'users',
  listComponent: UsersListComponent,
  editorComponent: UserEditorComponent,
  viewComponent: UserComponent
}
]

import EditorMode from './dataTypes/editorMode';

function getTypeRoutes(type) {
  return [{
    path: `${type.basePath}`,
    component: type.listComponent,
  }, {
    path: `${type.basePath}/edit/:id`,
    component: type.editorComponent,
    data: { mode: EditorMode.EDIT }
  }, {
    path: `${type.basePath}/new`,
    component: type.editorComponent,
    data: { mode: EditorMode.NEW }
  }, {
    path: `${type.basePath}/:id`,
    component: type.viewComponent
  }]
}

const routes: Routes = [
  ...types.map(type => getTypeRoutes(type)).flat(), {
    path: 'sign-in',
    component: SignInComponent
  }, {
    path: 'orders',
    component: OrdersListComponent
  }, {
    path: 'orders/:id',
    component: OrderComponent
  }, {
    path: 'settings',
    component: SettingsComponent
  }, {
    path: '**', redirectTo: 'cloths', pathMatch: 'full'
  }]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
