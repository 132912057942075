export default {
    TITLE: "لوحة التحكم - الزوايا الستة عشر",
    ERROR_CONNECTING_TO_SERVER: "حصل خطأ أثناء الإتصال بالسيرفر",
    LOADING: "جاري التحميل...",
    NOT_FOUND_IN_DATABASE: {
        cloths: "لا يوجد أقمشة في قاعدة البيانات بعد.",
        accessories: "لا يوجد إكسسوارات في قاعدة البيانات بعد.",
        models: "لا يوجد موديلات في قاعدة البيانات بعد.",
        unclassifiedProducts: "لا يوجد سلع أخرى في قاعدة البيانات بعد.",
        users: "لا يوجد العملاء في قاعدة البيانات بعد."
    },
    ADD_ITEM: {
        cloths: "إضافة القماش لقاعدة البيانات",
        accessories: "إضافة الإكسسوار لقاعدة البيانات",
        models: "إضافة الموديل لقاعدة البيانات",
        unclassifiedProducts: "إضافة السلعة لقاعدة البيانات",
        users: "إضافة الحساب لقاعدة البيانات"
    },
    EDIT_ITEM: {
        cloths: "تعديل القماش",
        accessories: "تعديل الإكسسوار",
        models: "تعديل الموديل",
        unclassifiedProducts: "تعديل السلعة",
        users: "تعديل الحساب"
    },
    ITEM_TYPES: {
        cloths: "الأقمشة",
        accessories: "الإكسسوارات",
        models: "الموديلات",
        unclassifiedProducts: "سلع أخرى",
        users: "العملاء",
        orders: "الطلبات",
        settings: "الإعدادات"
    },
    SAUDI_RIYAL: "ريال سعودي",
    ERROR_DELETING_DATA: "حصل خطأ أثناء محاولة حذف البيانات.",
    EDIT: "تعديل",
    DELETE: "حذف",
    NAME: "الإسم",
    TYPE: "النوع",
    PRICE: "السعر",
    PRICE_BEFORE_OFFER: "السعر قبل العرض",
    EXTRA_PRICE: "السعر الإضافي",
    MODEL_CODE: "كود الموديل",
    ERROR_MODEL_CODE_LENGTH: "يجب أن يكون كود الموديل من 4 خانات",
    RATING: "التقييم",
    DESCRIPTION: "الوصف",
    IMAGE: "الصورة",
    PHONE: "رقم الهاتف",
    COUNTRY: "بلد الصناعة",
    SIZE: "المقاس",
    AVAILABLE_COLORS: "الألوان المتاحة",
    CLASSIFICATION: "التصنيف",
    CLASSIFICATIONS: {
        REGULAR: "ثوب عادي",
        OCCASION: "حفلات ومناسبات"
    },
    SIGN_IN_FAILED: "فشل تسجيل الدخول.",
    SIGN_IN: "تسجيل الدخول",
    ENTER_EMAIL: "أدخل البريد الإلكتروني",
    ENTER_PASSWORD: "أدخل كلمة المرور",
    SIGN_OUT_FAILED: "فشل تسجيل الخروج.",
    SIGN_OUT: "تسجيل الخروج",
    UPLOADING: "جاري رفع البيانات...",
    ERROR_UPLOADING: "حدث خطأ أثناء رفع البيانات.",
    UPLOAD_SUCCESSFUL: "تم رفع البيانات.",
    UPLOADING_IMAGE: "جاري رفع الصورة...",
    ERROR_UPLOADING_IMAGE: "حدث خطأ أثناء رفع الصورة.",
    IMAGE_UPLOAD_SUCCESSFUL: "تم رفع الصورة.",
    ERROR_MUST_HAVE_COLORS: "يجب أن يكون هناك لون متاح واحد على الأقل.",
    ERROR_MUST_HAVE_IMAGE: "يجب أن يكون هناك صورة.",
    ERROR_RATING_RANGE: "يجب أن يكون التقييم بين 0 و 5.",
    ERROR_NO_EMPTY_VALUES: "لا تترك أي حقل فارغاً",
    NUM_OF_PEOPLE: "عدد الأشخاص المسجلين",
    PEOPLE: "الأشخاص",
    ERROR_NO_NEW_USER: "لا يسمح بإنشاء حسابات عادية من هنا.",
    INCH: "إنش",
    CM: "سنتيمتر",
    AGE: "العمر",
    HEIGHT: "الطول",
    RELATIONSHIP: "العلاقة",
    RELATIONSHIPS: {
        OWNER: "مالك الحساب",
        SON: "إبن",
        FATHER: "أب",
        BROTHER: "أخ"
    },
    ENTER_NAME: {
        OWNER: "إسم مالك الحساب",
        SON: "إسم الإبن",
        FATHER: "إسم الأب",
        BROTHER: "إسم الأخ"
    },
    SLEEVE_LENGTH: "طول الكم",
    SHOULDER_LENGTH: "مقاس الكتف",
    CHEST_LENGTH: "مقاس الصدر",
    NECK_LENGTH: "مقاس الرقبة",
    HAND_SIZE: "وسع اليد",
    KABAK_LENGTH: "طول الكبك",
    DOWN_LENGTH: "العرض السفلي",
    ADD_PERSON: "إضافة شخص",
    RELATIONSHIPS_DELETE: {
        OWNER: "مالك الحساب",
        SON: "الإبن",
        FATHER: "الأب",
        BROTHER: "الأخ"
    },
    ERROR_ONE_PERSON: "يجب أن يحتوي الحساب على شخص واحد على الأقل.",
    ERROR_SAME_PHONE: "لا يمكن تكرار رقم الهاتف بين أكثر من مستخدم.",
    OUT_OF_10: "من 10",
    FIXED_DELIVERY_TIME: "وقت توصيل السلع التي لا تحتاج تفصيل",
    DYNAMIC_DELIVERY_TIME: "وقت توصيل السلع التي تحتاج إلى تفصيل",
    IN_DAYS: "بالأيام",
    UPDATE: "حفظ التعديلات",
    ERROR_INVALID_INPUT: "البيانات التي أدخلتها تحتوي على خطأ.",
    ORDER: "الطلب ",
    ORDER_DATE: "تاريخ الطلب",
    DELIVERY_DATE: "تاريخ التوصيل",
    ITEMS: "تفاصيل السلع",
    ORDER_STATUS: "حالة الطلب",
    PENDING: "جاري العمل على الطلب",
    DELIVERED: "تم تسليم الطلب",
    TOTAL_PRICE: "السعر الإجمالي",
    ORDERS_NOT_DONE: "طلبات جاري العمل عليها",
    ORDERS_DONE: "طلبات تم إنهاؤها",
    NO_ORDERS: "لا يوجد طلبات",
    PRODUCT: "السلعة",
    QUANTITY: "الكمية",
    TOTAL: "الإجمالي",
    COLOR: "اللون",
    SET_DELIVERED: "تم إتمام الطلب",
    SET_PENDING: "إعادة حالة الطلب إلى غير منتهي"
}