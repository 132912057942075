import { Component, OnInit } from '@angular/core';
import Strings from '../dataTypes/strings'
import { AlertService } from '../services/alertService/alert.service';
import { APIService } from '../services/apiService/api.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  isLoading = true
  isError = false
  Strings = Strings
  deliveryTime

  constructor(private api: APIService, private alert: AlertService) { }

  async ngOnInit() {
    const { isRequestSuccessful, deliveryTime } = await this.api.getDeliveryTime()
    this.deliveryTime = deliveryTime
    this.isLoading = false
    this.isError = !isRequestSuccessful
    if (this.isError) this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
  }

  Number(n) {
    return Number(n)
  }

  async updateSettings() {
    if (typeof this.deliveryTime?.fixed === 'number' && this.deliveryTime?.fixed > 0 && typeof this.deliveryTime?.dynamic === 'number' && this.deliveryTime?.dynamic > 0) {
      const {isRequestSuccessful} = await this.api.setDeliveryTime(this.deliveryTime)
      if(!isRequestSuccessful) this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
      else this.alert.success(Strings.UPLOAD_SUCCESSFUL)
    }
    else this.alert.error(Strings.ERROR_INVALID_INPUT)
  }
}
