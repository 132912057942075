import { Component } from '@angular/core'

import { AlertService } from '../services/alertService/alert.service'
import { AuthService } from '../services/authService/auth.service'
import { APIService } from '../services/apiService/api.service'

import Strings from '../dataTypes/strings'
import ItemTypes from '../dataTypes/itemType'

@Component({
  selector: 'app-login',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SignInComponent {
  itemType = ItemTypes.USERS
  email: string = ""
  password: string = ""
  Strings = Strings

  constructor(private auth: AuthService, private alert: AlertService, private api: APIService) { }

  async login() {
    const { isRequestSuccessful, item: user } = await this.api.getItem(this.itemType, this.email)
    if (!(isRequestSuccessful && user.isAdmin)) {
      this.alert.error(Strings.SIGN_IN_FAILED)
      return
    }
    const isSignInSuccessful = await this.auth.signIn(this.email, this.password)
    if (!isSignInSuccessful) this.alert.error(Strings.SIGN_IN_FAILED)
  }
}
