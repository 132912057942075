<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>

<main class="grid" *ngIf="!isLoading">
    <label for="phoneNumber">{{Strings.PHONE}}</label>
    <div dir="ltr" class="phone-number-container">
        <img src="../../../assets/saudi_arabia.svg">
        <p>+966</p>
        <input type="tel" id="phoneNumber" pattern="[0-9]*" required [value]="item.phoneNumber" (input)="item.phoneNumber=$event.target.value">
    </div>
    <hr class="span">
    <p class="span"><strong>{{Strings.PEOPLE}}: </strong></p>
    <button class="span" (click)="addPerson()">
        {{Strings.ADD_PERSON}}
    </button>
    <div class="grid span" *ngFor="let person of item.people; index as i;">
        <hr class="span">
        <label [for]="person.id + 'relationship'">{{Strings.RELATIONSHIP}}: </label>
        <select [id]="person.id + 'relationship'" [value]="person.relationship" (input)="person.relationship=$event.target.value" required>
            <option value="OWNER">{{Strings.RELATIONSHIPS.OWNER}}</option>
            <option value="SON">{{Strings.RELATIONSHIPS.SON}}</option>
            <option value="BROTHER">{{Strings.RELATIONSHIPS.BROTHER}}</option>
            <option value="FATHER">{{Strings.RELATIONSHIPS.FATHER}}</option>
        </select>
        <label [for]="person.id + 'name'">{{Strings.ENTER_NAME[person.relationship]}}: </label>
        <input [id]="person.id + 'name'" type="text" [value]="person.name" (input)="person.name=$event.target.value" required>
        <label [for]="person.id + 'age'">{{Strings.AGE}}: </label>
        <input [id]="person.id + 'age'" type="number" [value]="person.age" (input)="person.age=$event.target.value" min="0" required>
        <label [for]="person.id + 'height'">{{Strings.HEIGHT}}: </label>
        <input [id]="person.id + 'height'" type="number" [value]="person.height" (input)="person.height=$event.target.value" min="1" required>
        <label [for]="person.id + 'shoulderLength'">{{Strings.SHOULDER_LENGTH}}: </label>
        <input [id]="person.id + 'shoulderLength'" type="number" [value]="person.shoulderLength" (input)="person.shoulderLength=$event.target.value" min="1" required>
        <label [for]="person.id + 'sleeveLength'">{{Strings.SLEEVE_LENGTH}}: </label>
        <input [id]="person.id + 'sleeveLength'" type="number" [value]="person.sleeveLength" (input)="person.sleeveLength=$event.target.value" min="1" required>
        <label [for]="person.id + 'chestLength'">{{Strings.CHEST_LENGTH}}: </label>
        <input [id]="person.id + 'chestLength'" type="number" [value]="person.chestLength" (input)="person.chestLength=$event.target.value" min="1" required>
        <label [for]="person.id + 'neckLength'">{{Strings.NECK_LENGTH}}: </label>
        <input [id]="person.id + 'neckLength'" type="number" [value]="person.neckLength" (input)="person.neckLength=$event.target.value" min="1" required>
        <label [for]="person.id + 'handSize'">{{Strings.HAND_SIZE}}: </label>
        <input [id]="person.id + 'handSize'" type="number" [value]="person.handSize" (input)="person.handSize=$event.target.value" min="1" required>
        <label [for]="person.id + 'kabakLength'">{{Strings.KABAK_LENGTH}}: </label>
        <input [id]="person.id + 'kabakLength'" type="number" [value]="person.kabakLength" (input)="person.kabakLength=$event.target.value" min="1" required>
        <label [for]="person.id + 'downLength'">{{Strings.DOWN_LENGTH}}: </label>
        <input [id]="person.id + 'downLength'" type="number" [value]="person.downLength" (input)="person.downLength=$event.target.value" min="1" required>
        <button class="span" (click)="deletePerson(person.id)">
            {{Strings.DELETE}} {{Strings.RELATIONSHIPS_DELETE[person.relationship]}} {{person.name}}
        </button>
        <hr class="span">
    </div>
    <button (click)="putItem()">
        {{this.mode === EditorMode.EDIT ? Strings.EDIT_ITEM[itemType] : Strings.ADD_ITEM[itemType]}}
    </button>
</main>