import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { StateService } from './services/stateService/state.service';
import { AuthService } from './services/authService/auth.service';
import { APIService } from './services/apiService/api.service';
import { AlertService } from './services/alertService/alert.service';
import { StorageService } from './services/storageService/storage.service';
import { IdService } from './services/idService/id.service';

import { SignInComponent } from './signin/signin.component';
import { ClothsListComponent } from './cloths/cloths-list/cloths-list.component';
import { ClothComponent } from './cloths/cloth/cloth.component';
import { ClothEditorComponent } from './cloths/cloth-editor/cloth-editor.component';
import { AccessoriesListComponent } from './accessories/accessories-list/accessories-list.component';
import { AccessoryComponent } from './accessories/accessory/accessory.component';
import { AccessoryEditorComponent } from './accessories/accessory-editor/accessory-editor.component';
import { ModelsListComponent } from './models/models-list/models-list.component';
import { ModelComponent } from './models/model/model.component';
import { ModelEditorComponent } from './models/model-editor/model-editor.component';
import { UnclassifiedProductListComponent } from './unclassifiedProducts/unclassified-product-list/unclassified-product-list.component';
import { UnclassifiedProductComponent } from './unclassifiedProducts/unclassifiedProduct/unclassified.product.component';
import { UnclassifiedProductEditorComponent } from './unclassifiedProducts/unclassified-product-editor/unclassified-product-editor.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserComponent } from './users/user/user.component';
import { UserEditorComponent } from './users/user-editor/user-editor.component';
import { SettingsComponent } from './settings/settings.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderComponent } from './orders/order/order.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ClothsListComponent,
    ClothComponent,
    ClothEditorComponent,
    AccessoriesListComponent,
    AccessoryComponent,
    AccessoryEditorComponent,
    ModelsListComponent,
    ModelComponent,
    ModelEditorComponent,
    UnclassifiedProductListComponent,
    UnclassifiedProductComponent,
    UnclassifiedProductEditorComponent,
    UsersListComponent,
    UserComponent,
    UserEditorComponent,
    SettingsComponent,
    OrdersListComponent,
    OrderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [StateService, AuthService, APIService, AlertService, StorageService, IdService],
  bootstrap: [AppComponent],
})
export class AppModule { }
