import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { StateService } from 'src/app/services/stateService/state.service'
import { APIService } from 'src/app/services/apiService/api.service'
import { AlertService } from 'src/app/services/alertService/alert.service'

import ItemTypes from '../../dataTypes/itemType'
import Strings from '../../dataTypes/strings'
import { StorageService } from 'src/app/services/storageService/storage.service'

@Component({
	selector: 'app-unclassified-product',
	templateUrl: './unclassified.product.component.html',
	styleUrls: ['./unclassified.product.component.css']
})
export class UnclassifiedProductComponent implements OnInit {
	itemType = ItemTypes.UNCLASSIFIED_PRODUCTS
	Strings = Strings
	isLoading = true
	itemId = ''
	item

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateService,
		private api: APIService,
		private alert: AlertService,
		private storage: StorageService) { }

	async ngOnInit() {
		this.route.paramMap.subscribe(async paramMap => {
			this.itemId = paramMap.get('id')
			this.item = await this.getItem()
			this.isLoading = false
		})
	}

	private async getItem() {
		this.isLoading = true
		let item = this.state[this.itemType].find(item => item.id === this.itemId)
		if (!item) {
			const { isRequestSuccessful, item: i } = await this.api.getItem(this.itemType, this.itemId)
			item = i
			if (!isRequestSuccessful) {
				this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
				this.router.navigate(['../'])
			}
		}
		return item
	}

	async deleteItem() {
		const isImageDeleteSuccessful = await this.storage.deleteItemImage(this.itemType, this.itemId)
		const { isRequestSuccessful } = await this.api.deleteItem(this.itemType, this.item.id)
		if (isImageDeleteSuccessful && isRequestSuccessful) {
			this.state[this.itemType] = this.state[this.itemType].filter(item => item.id !== this.item.id)
			this.router.navigateByUrl(this.itemType)
		} else this.alert.error(Strings.ERROR_DELETING_DATA)
	}

	colorTracker(index: number, color: string) { return color }
}
