import { Injectable } from '@angular/core';
import firebase from '../firebaseService/firebase.service'

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	private rootRef = firebase.storage().ref()

	constructor() { }

	async uploadItemImage(itemType: string, id: string, image: string) {
		try {
			const imageRef = this.rootRef.child(`images/${itemType}/${id}`)
			await imageRef.putString(image, 'data_url')
			const imageUrl = await imageRef.getDownloadURL()
			return { isUploadSuccessful: true, imageUrl }
		} catch (e) {
			console.error(e)
			return { isUploadSuccessful: false }
		}
	}

	async deleteItemImage(itemType: string, id: string) {
		try {
			const imageRef = this.rootRef.child(`images/${itemType}/${id}`)
			await imageRef.delete()
			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}
}
