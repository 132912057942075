import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { AuthService } from '../authService/auth.service'

@Injectable({
  providedIn: 'root'
})
export class APIService {
  constructor(private auth: AuthService) { }

  async getCollection(itemType: string) {
    try {
      const url = `${environment.baseApiUrl}/${itemType}`
      const config = {
        method: HTTPMethod.GET
      }
      const response = await fetch(url, config)
      const items = (await response.json()).items
      const isRequestSuccessful = response.status === 200
      return { isRequestSuccessful, items }
    } catch (e) {
      console.error(e)
      return { isRequestSuccessful: false }
    }
  }

  async getItem(itemType: string, id: string) {
    try {
      const url = `${environment.baseApiUrl}/${itemType}/${id}`
      const config = {
        method: HTTPMethod.GET
      }
      const response = await fetch(url, config)
      const item = (await response.json()).item
      const isRequestSuccessful = response.status === 200
      return { isRequestSuccessful, item }
    } catch (e) {
      console.error(e)
      return { isRequestSuccessful: false }
    }
  }

  async putItem(itemType: string, id: string, item: any) {
    try {
      const url = `${environment.baseApiUrl}/${itemType}/${id}`
      const config: any = {
        method: HTTPMethod.PUT,
        headers: {
          "Authorization": `Bearer ${this.auth.authorizationToken}`
        },
        body: JSON.stringify({ item })
      }
      const response = await fetch(url, config)
      const newId = (await response.json()).id
      const isRequestSuccessful = response.status === 200
      return { isRequestSuccessful, id: newId }
    } catch (e) {
      console.error(e)
      return { isRequestSuccessful: false }
    }
  }

  async deleteItem(itemType: string, id: string) {
    try {
      const url = `${environment.baseApiUrl}/${itemType}/${id}`
      const config = {
        method: HTTPMethod.DELETE,
        headers: {
          "Authorization": `Bearer ${this.auth.authorizationToken}`
        }
      }
      const response = await fetch(url, config)
      const isRequestSuccessful = response.status === 200
      return { isRequestSuccessful }
    } catch (e) {
      console.error(e)
      return { isRequestSuccessful: false }
    }
  }

  async getDeliveryTime() {
    try {
      const url = `${environment.baseApiUrl}/deliveryTime`
      const config = {
        method: HTTPMethod.GET
      }
      const response = await fetch(url, config)
      const deliveryTime = (await response.json()).deliveryTime
      const isRequestSuccessful = response.status === 200
      return { isRequestSuccessful, deliveryTime }
    } catch (e) {
      console.error(e)
      return { isRequestSuccessful: false }
    }
  }

  async setDeliveryTime(deliveryTime: any) {
    try {
      const url = `${environment.baseApiUrl}/deliveryTime`
      const config = {
        method: HTTPMethod.PUT,
        headers: {
          "Authorization": `Bearer ${this.auth.authorizationToken}`
        },
        body: JSON.stringify({ deliveryTime })
      }
      const response = await fetch(url, config)
      const isRequestSuccessful = response.status === 200
      return { isRequestSuccessful }
    } catch (e) {
      console.error(e)
      return { isRequestSuccessful: false }
    }
  }
}

class HTTPMethod {
  static readonly GET = "GET"
  static readonly PUT = "PUT"
  static readonly DELETE = "DELETE"
}