<header role="banner" *ngIf="auth.isUserAuthenticated">
	<img alt="شعار الزوايا الستة عشر" src="../assets/logo.jpg" />
	<span>{{Strings.TITLE}}</span>
	<div class="spacer"></div>
	<nav>
		<a *ngFor="let itemType of itemTypes; index as i;" [routerLink]="[itemType]">
			{{Strings.ITEM_TYPES[itemType]}}
		</a>
		<a (click)="signOut()">{{Strings.SIGN_OUT}}</a>
	</nav>
</header>


<main>
	<router-outlet></router-outlet>
</main>

<div id="alerts">
	<div [class]="['alert', alert.clazz].join(' ')" *ngFor="let alert of alert.alerts; index as i; trackBy: alertTracker;" (animationend)="closeAlert(alert.id)">
		{{alert.msg}} <span class="close" (click)="closeAlert(alert.id)">&times;</span>
	</div>
</div>