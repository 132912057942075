import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { APIService } from 'src/app/services/apiService/api.service';
import { StateService } from 'src/app/services/stateService/state.service';

import Strings from '../../dataTypes/strings'

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit {
  isLoading = true
  isError = false
  Strings = Strings
  constructor(
    public state: StateService,
    public router: Router,
    private api: APIService,
    private alert: AlertService
  ) { }

  async ngOnInit() {
    await this.getUsers()
  }

  async getUsers() {
    this.isLoading = true
    this.isError = false
    if (!this.state.users?.length) {
      const { isRequestSuccessful, items: users } = await this.api.getCollection('users')
      this.isError = !isRequestSuccessful
      if (this.isError) this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
      else this.state.users = users
    }
    this.isLoading = false
  }

  get orders() {
    this.state.users?.forEach(user => user.orders?.forEach(order => {
      order.userId = user.id
      order.userPhoneNumber = user.phoneNumber
    }))
    const orders = this.state.users?.filter(user => user.orders).map(user => user.orders).flat()
    orders.sort((a, b) => a.deliveryDate - b.deliveryDate)
    return orders
  }

  get completedOrders() {
    return this.orders.filter(o => o.isDelivered)
  }

  get uncompletedOrders() {
    return this.orders.filter(o => !o.isDelivered)
  }

  arabicDate(mls) {
    const date = new Date(mls)
    return date.toLocaleDateString('ar', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' })
  }
}
