<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>

<main *ngIf="!isLoading">
    <div class="actions">
        <button [routerLink]="'../edit/' + item.id"><img src="../../../assets/edit.svg"> {{Strings.EDIT}}</button>
        <button (click)="deleteItem()"><img src="../../../assets/delete.svg"> {{Strings.DELETE}}</button>
    </div>
    <p><strong>{{Strings.PHONE}}: </strong><span dir="ltr">{{item.phoneNumber}}</span></p>
    <p><strong>{{Strings.PEOPLE}}: </strong></p>
    <span *ngFor="let person of item.people; index as i;">
        <hr>
        <p><strong>{{Strings.NAME}}: </strong>{{person.name}}</p>
        <p><strong>{{Strings.AGE}}: </strong>{{person.age}}</p>
        <p><strong>{{Strings.RELATIONSHIP}}: </strong>{{Strings.RELATIONSHIPS[person.relationship]}}</p>
        <p><strong>{{Strings.HEIGHT}}: </strong>{{person.height}} {{Strings.INCH}}</p>
        <p><strong>{{Strings.SHOULDER_LENGTH}}: </strong>{{person.shoulderLength}} {{Strings.INCH}}</p>
        <p><strong>{{Strings.SLEEVE_LENGTH}}: </strong>{{person.sleeveLength}} {{Strings.INCH}}</p>
        <p><strong>{{Strings.CHEST_LENGTH}}: </strong>{{person.chestLength}} {{Strings.INCH}}</p>
        <p><strong>{{Strings.NECK_LENGTH}}: </strong>{{person.neckLength}} {{Strings.CM}}</p>
        <p><strong>{{Strings.HAND_SIZE}}: </strong>{{person.handSize}} {{Strings.INCH}}</p>
        <p><strong>{{Strings.KABAK_LENGTH}}: </strong>{{person.kabakLength}} {{Strings.INCH}}</p>
        <p><strong>{{Strings.DOWN_LENGTH}}: </strong>{{person.downLength}} {{Strings.INCH}}</p>
        <hr>
    </span>
</main>