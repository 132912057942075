import { Component, OnInit } from '@angular/core'

import { StateService } from '../../services/stateService/state.service'
import { APIService } from '../../services/apiService/api.service'
import { AlertService } from 'src/app/services/alertService/alert.service'

import ItemTypes from '../../dataTypes/itemType'
import Strings from '../../dataTypes/strings'
import { StorageService } from 'src/app/services/storageService/storage.service'
import { AuthService } from 'src/app/services/authService/auth.service'

@Component({
	selector: 'app-users-list',
	templateUrl: './users-list.component.html',
	styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
	itemType = ItemTypes.USERS
	Strings = Strings
	isLoading = true
	isError = false

	constructor(
		public state: StateService,
		private api: APIService,
		private alert: AlertService) { }

	async ngOnInit() {
		await this.getItems()
	}

	async getItems() {
		this.isLoading = true
		this.isError = false
		if (!this.state[this.itemType].length) {
			const { isRequestSuccessful, items } = await this.api.getCollection(this.itemType)
			this.isError = !isRequestSuccessful
			if (this.isError) this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
			else this.state[this.itemType] = items
		}
		this.isLoading = false
	}

	async deleteItem(id: string) {
		const { isRequestSuccessful } = await this.api.deleteItem(this.itemType, id)
		if (!isRequestSuccessful) this.alert.error(Strings.ERROR_DELETING_DATA)
		else this.state[this.itemType] = this.state[this.itemType].filter(item => item.id !== id)
	}

	itemTracker(index, item) { return item.id }

	get stateItems() {
		return this.state[this.itemType].filter(user => !user.isAdmin);
	}
}
