const itemTypes = {
    CLOTHS: "cloths",
    ACCESSORIES: 'accessories',
    MODELS: 'models',
    UNCLASSIFIED_PRODUCTS: 'unclassifiedProducts',
    USERS: 'users',
    ORDERS: 'orders',
    SETTINGS: 'settings'
}

export default itemTypes