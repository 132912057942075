<div class="msg" *ngIf="isLoading && !isError">
    <p>{{Strings.LOADING}}</p>
</div>

<div class="msg" *ngIf="!isLoading && isError">
    <p>{{Strings.ERROR_CONNECTING_TO_SERVER}}</p>
</div>

<main *ngIf="!isLoading && !isError">
    <div id="order">
        <button (click)="toggleState()">{{order.isDelivered ? Strings.SET_PENDING : Strings.SET_DELIVERED}}</button>
        <p class="large"><strong>{{Strings.ORDER_STATUS}}:</strong> {{order.isDelivered ? Strings.DELIVERED : Strings.PENDING}}</p>
        <p class="large"><strong>{{Strings.ORDER_DATE}}:</strong> {{arabicDate(order.orderDate)}}</p>
        <p class="large"><strong>{{Strings.DELIVERY_DATE}}:</strong> {{arabicDate(order.deliveryDate)}}</p>
        <p class="large"><strong>{{Strings.TOTAL_PRICE}}:</strong> {{order.totalPrice}} {{Strings.SAUDI_RIYAL}}</p>
        <p class="large"><strong>{{Strings.ITEMS}}:</strong></p>
        <div id="items">
            <hr>
            <strong>{{Strings.PRODUCT}}</strong>
            <strong>{{Strings.QUANTITY}}</strong>
            <strong>{{Strings.PRICE}}</strong>
            <strong>{{Strings.TOTAL}}</strong>
            <hr>
            <ng-container *ngFor="let item of order.items; index as j">
                <div class="info">
                    <img [src]="item.itemA.imageUrl">
                    <div>
                        <p><strong>{{item.itemA.name || item.itemA.code}}</strong></p>
                        <p *ngIf="item.itemB?.type">{{item.itemB.type}}</p>
                        <p *ngIf="item.person">{{Strings.RELATIONSHIPS[item.person.relationship]}} - {{item.person.name}}</p>
                        <div class="color-container" *ngIf="item.color"><span>{{Strings.COLOR}}</span><span class="color" [style.backgroundColor]="item.color">&nbsp;</span></div>
                    </div>
                </div>
                <span>{{item.quantity}}</span>
                <span>{{getItemPrice(item)}} {{Strings.SAUDI_RIYAL}}</span>
                <span>{{getItemTotalPrice(item)}} {{Strings.SAUDI_RIYAL}}</span>
                <hr>
            </ng-container>
        </div>
    </div>
    <div id="user">
        <p><strong>{{Strings.PHONE}}: </strong><span dir="ltr">{{user.phoneNumber}}</span></p>
        <p><strong>{{Strings.PEOPLE}}: </strong></p>
        <hr>
        <span *ngFor="let person of user.people; index as i;">
            <p><strong>{{Strings.NAME}}: </strong>{{person.name}}</p>
            <p><strong>{{Strings.AGE}}: </strong>{{person.age}}</p>
            <p><strong>{{Strings.RELATIONSHIP}}: </strong>{{Strings.RELATIONSHIPS[person.relationship]}}</p>
            <p><strong>{{Strings.HEIGHT}}: </strong>{{person.height}} {{Strings.INCH}}</p>
            <p><strong>{{Strings.SHOULDER_LENGTH}}: </strong>{{person.shoulderLength}} {{Strings.INCH}}</p>
            <p><strong>{{Strings.SLEEVE_LENGTH}}: </strong>{{person.sleeveLength}} {{Strings.INCH}}</p>
            <p><strong>{{Strings.CHEST_LENGTH}}: </strong>{{person.chestLength}} {{Strings.INCH}}</p>
            <p><strong>{{Strings.NECK_LENGTH}}: </strong>{{person.neckLength}} {{Strings.CM}}</p>
            <p><strong>{{Strings.HAND_SIZE}}: </strong>{{person.handSize}} {{Strings.INCH}}</p>
            <p><strong>{{Strings.KABAK_LENGTH}}: </strong>{{person.kabakLength}} {{Strings.INCH}}</p>
            <p><strong>{{Strings.DOWN_LENGTH}}: </strong>{{person.downLength}} {{Strings.INCH}}</p>
            <hr>
        </span>
    </div>
</main>