import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  cloths = []
  accessories = []
  models = []
  unclassifiedProducts = []
  users = []

  constructor() { }
}
