<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>

<main *ngIf="!isLoading">
    <label for="code">{{Strings.MODEL_CODE}}: </label>
    <input id="code" type="text" [value]="item.code" (input)="item.code=$event.target.value" minlength="4" maxlength="4" required>

    <label for="price">{{Strings.EXTRA_PRICE}}: </label>
    <input id="price" type="number" [value]="item.price" (input)="item.price=$event.target.value" min="0" required>

    <label for="priceBeforeOffer">{{Strings.PRICE_BEFORE_OFFER}}: </label>
    <input id="priceBeforeOffer" type="number" [value]="item.priceBeforeOffer" (input)="item.priceBeforeOffer=$event.target.value" min="0" required>

    <label for="imageUrl">{{Strings.IMAGE}}: </label>
    <input id="imageUrl" type="file" accept=".jpg, .jpeg, .png" (input)="onImgSelected($event)" required>
    <div></div>
    <img id="uploaded-img" [src]="item.imageUrl">

    <button (click)="putItem()">
        {{this.mode === EditorMode.EDIT ? Strings.EDIT_ITEM[itemType] : Strings.ADD_ITEM[itemType]}}
    </button>
</main>