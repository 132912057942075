import { Component, OnInit } from '@angular/core'

import { StateService } from '../../services/stateService/state.service'
import { APIService } from '../../services/apiService/api.service'
import { AlertService } from 'src/app/services/alertService/alert.service'

import ItemTypes from '../../dataTypes/itemType'
import Strings from '../../dataTypes/strings'
import { StorageService } from 'src/app/services/storageService/storage.service'

@Component({
	selector: 'app-cloths-list',
	templateUrl: './cloths-list.component.html',
	styleUrls: ['./cloths-list.component.css']
})
export class ClothsListComponent implements OnInit {
	itemType = ItemTypes.CLOTHS
	Strings = Strings
	isLoading = true
	isError = false

	constructor(
		public state: StateService,
		private api: APIService,
		private alert: AlertService,
		private storage: StorageService) { }

	async ngOnInit() {
		await this.getItems()
	}

	async getItems() {
		this.isLoading = true
		this.isError = false
		if (!this.state[this.itemType].length) {
			const { isRequestSuccessful, items } = await this.api.getCollection(this.itemType)
			this.isError = !isRequestSuccessful
			if (this.isError) this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
			else this.state[this.itemType] = items
		}
		this.isLoading = false
	}

	async deleteItem(id: string) {
		const isImageDeleteSuccessful = await this.storage.deleteItemImage(this.itemType, id)
		const { isRequestSuccessful } = await this.api.deleteItem(this.itemType, id)
		if (!(isRequestSuccessful && isImageDeleteSuccessful)) this.alert.error(Strings.ERROR_DELETING_DATA)
		else this.state[this.itemType] = this.state[this.itemType].filter(item => item.id !== id)
	}

	itemTracker(index, item) { return item.id }
}
