import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AlertService } from 'src/app/services/alertService/alert.service'
import { StateService } from 'src/app/services/stateService/state.service'
import { APIService } from 'src/app/services/apiService/api.service'

import ItemTypes from 'src/app/dataTypes/itemType'
import EditorMode from '../../dataTypes/editorMode'
import Strings from '../../dataTypes/strings'
import { IdService } from 'src/app/services/idService/id.service'

@Component({
	selector: 'app-user-editor',
	templateUrl: './user-editor.component.html',
	styleUrls: ['./user-editor.component.css']
})
export class UserEditorComponent implements OnInit {
	EditorMode = EditorMode
	itemType = ItemTypes.USERS
	mode: EditorMode
	itemId = ''
	item
	isLoading = true
	Strings = Strings
	constructor(
		private state: StateService,
		private alert: AlertService,
		private router: Router,
		private route: ActivatedRoute,
		private api: APIService,
		private id: IdService) { }

	async ngOnInit() {
		this.route.paramMap.subscribe(async paramMap => {
			this.itemId = paramMap.get('id')
			this.mode = this.route.snapshot.data.mode
			if (this.mode === EditorMode.EDIT) this.item = await this.getItem()
			else this.item = this.newItem()
			this.isLoading = false
		})
	}

	private newItem() {
		return {
			id: this.id.generateItemId(this.itemType),
			phoneNumber: '',
			people: [{
				id: this.id.generateItemId(this.itemType),
				name: '',
				relationship: 'OWNER',
			}]
		}
	}

	private async getItem() {
		this.isLoading = true
		let item = this.state[this.itemType].find(item => item.id === this.itemId)
		if (!item) {
			const { isRequestSuccessful, item: i } = await this.api.getItem(this.itemType, this.itemId)
			item = i
			if (!isRequestSuccessful) {
				this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
				this.router.navigate(['../../'])
			}
		}
		item.phoneNumber = item.phoneNumber.replace('+966', '')
		return JSON.parse(JSON.stringify(item))
	}

	addPerson() {
		this.item.people = this.item.people ? this.item.people : []
		this.item.people.push({
			id: this.id.generateItemId(this.itemType),
			name: '',
			relationship: 'OWNER',
		})
	}

	deletePerson(id) {
		this.item.people = this.item.people.filter(person => person.id !== id)
	}

	async putItem() {
		this.item.phoneNumber = `+966${this.item.phoneNumber}`
		if (this.state.users.some(user => user.id !== this.item.id && user.phoneNumber === this.item.phoneNumber)) {
			this.alert.error(Strings.ERROR_SAME_PHONE)
			return
		}
		let isEmptyValue
		this.item.people.forEach(person => {
			for (const key in person) {
				if (person.hasOwnProperty(key)) isEmptyValue ||= person[key] === null || person[key] === undefined || person[key] === ''
			}
			if (!isEmptyValue) {
				person.age = Number(person.age)
				person.height = Number(person.height)
				person.sleeveLength = Number(person.sleeveLength)
				person.shoulderLength = Number(person.shoulderLength)
				person.chestLength = Number(person.chestLength)
				person.neckLength = Number(person.neckLength)
				person.handSize = Number(person.handSize)
				person.kabakLength = Number(person.kabakLength)
				person.downLength = Number(person.downLength)
			}
		})
		if (isEmptyValue) {
			this.alert.error(Strings.ERROR_NO_EMPTY_VALUES)
			return
		}
		this.alert.info(Strings.UPLOADING)
		const { isRequestSuccessful, id } = await this.api.putItem(this.itemType, this.item.id, this.item)
		if (!isRequestSuccessful) this.alert.error(Strings.ERROR_UPLOADING)
		else {
			if (this.mode === EditorMode.NEW) this.state[this.itemType].push({ ...this.item, id })
			else Object.assign(this.state[this.itemType].find(item => item.id === this.item.id), this.item)
			this.alert.success(Strings.UPLOAD_SUCCESSFUL)
			this.router.navigateByUrl(this.itemType)
		}
	}
}