import { Component } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'

import { AlertService } from './services/alertService/alert.service'
import { AuthService } from './services/authService/auth.service'

import Strings from './dataTypes/strings'
import itemTypes from './dataTypes/itemType'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	private lastUrl = '/'
	Strings = Strings
	itemTypes = Object.values(itemTypes)

	constructor(public alert: AlertService, public auth: AuthService, private router: Router) {
		router.events.subscribe((routerEvent) => {
			if (routerEvent instanceof NavigationEnd && routerEvent.urlAfterRedirects !== '/sign-in') {
				this.lastUrl = routerEvent.urlAfterRedirects
			}
		})
		this.auth.onAuthStateChanged(isUserAuthenticated => {
			if (!isUserAuthenticated) this.router.navigate(['sign-in'])
			else this.router.navigate([this.lastUrl])
		})
	}

	signOut() {
		const isSignOutSuccessful = this.auth.signOut()
		if (!isSignOutSuccessful) this.alert.error(Strings.SIGN_OUT_FAILED)
	}

	alertTracker(index, alert) { return alert.id }

	closeAlert(id: string){
		this.alert.alerts = this.alert.alerts.filter(alert => alert.id !== id)
	}
}
