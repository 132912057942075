<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>

<main *ngIf="!isLoading">
    <div class="actions">
        <button [routerLink]="'../edit/' + item.id"><img src="../../../assets/edit.svg"> {{Strings.EDIT}}</button>
        <button (click)="deleteItem()"><img src="../../../assets/delete.svg"> {{Strings.DELETE}}</button>
    </div>
    <div class="data">
        <img [src]="item.imageUrl">
        <div>
            <p><strong>{{Strings.NAME}}: </strong>{{item.name}}</p>
            <p><strong>{{Strings.PRICE}}: </strong>{{item.price}} {{Strings.SAUDI_RIYAL}}</p>
            <p><strong>{{Strings.PRICE_BEFORE_OFFER}}: </strong>{{item.priceBeforeOffer}} {{Strings.SAUDI_RIYAL}}</p>
            <p><strong>{{Strings.RATING}}: </strong>{{item.rating}}<img src="../../../assets/star.svg">  {{Strings.OUT_OF_10}}</p>
            <p>
                <strong>{{Strings.AVAILABLE_COLORS}}: </strong>
                <span *ngFor="let color of item.availableColors; index as i; trackBy: colorTracker;">
                    <span class="color" dir="ltr" [style.backgroundColor]="color" [title]="color">&nbsp;</span>&nbsp;
                </span>
            </p>
            <p><strong>{{Strings.SIZE}}: </strong>{{item.size}}</p>
            <p><strong>{{Strings.COUNTRY}}: </strong>{{item.countryOfOrigin}}</p>
        </div>
        <div>
            <p>{{item.description}}</p>
        </div>
    </div>
</main>