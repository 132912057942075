<div id="loading" *ngIf="isLoading && !isError">
    {{Strings.LOADING}}
</div>

<div id="error" *ngIf="isError">
    {{Strings.ERROR_CONNECTING_TO_SERVER}}
</div>

<div id="settings" *ngIf="!isLoading && !isError">
    <label for="fixed">{{Strings.FIXED_DELIVERY_TIME}} {{Strings.IN_DAYS}}: </label>
    <input id="fixed" type="number" [value]="deliveryTime.fixed" (input)="deliveryTime.fixed=Number($event.target.value)" min="1" required>
    <label for="dynamic">{{Strings.DYNAMIC_DELIVERY_TIME}} {{Strings.IN_DAYS}}: </label>
    <input id="dynamic" type="number" [value]="deliveryTime.dynamic" (input)="deliveryTime.dynamic=Number($event.target.value)" min="1" required>
    <button (click)="updateSettings()">{{Strings.UPDATE}}</button>
</div>