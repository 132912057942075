import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alertService/alert.service';
import { APIService } from 'src/app/services/apiService/api.service';
import { StateService } from 'src/app/services/stateService/state.service';

import Strings from '../../dataTypes/strings'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  isLoading = true
  isError = false
  Strings = Strings
  orderId

  constructor(
    public state: StateService,
    public router: Router,
    private api: APIService,
    private alert: AlertService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async paramMap => {
      this.orderId = paramMap.get('id')
      this.isLoading = true
      this.isError = false
      await this.getUsers()
    })
  }

  async getUsers() {
    this.isLoading = true
    this.isError = false
    if (!this.state.users?.length) {
      const { isRequestSuccessful, items: users } = await this.api.getCollection('users')
      this.isError = !isRequestSuccessful
      if (this.isError) this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
      else this.state.users = users
    }
    this.isLoading = false
  }

  get orders() {
    this.state.users?.forEach(user => user.orders?.forEach(order => {
      order.userId = user.id
      order.userPhoneNumber = user.phoneNumber
    }))
    const orders = this.state.users?.filter(user => user.orders).map(user => user.orders).flat()
    orders.sort((a, b) => a.deliveryDate - b.deliveryDate)
    if (!orders.some(o => o.id === this.orderId)) this.isError = true
    return orders
  }

  get order() {
    return this.orders.find(o => o.id === this.orderId)
  }

  get user() {
    return this.state.users.find(u => u.id === this.order.userId)
  }

  arabicDate(mls) {
    const date = new Date(mls)
    return date.toLocaleDateString('ar', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' })
  }

  getItemPrice(item) {
    return (item.itemA?.price || 0) + (item.itemB?.price || 0)
  }

  getItemTotalPrice(item) {
    return this.getItemPrice(item) * (item.quantity || 0)
  }

  async toggleState() {
    this.order.isDelivered = !this.order.isDelivered
    const user = JSON.parse(JSON.stringify(this.state.users.find(u => u.id === this.order.userId)))
    user.orders.forEach(order => {
      delete order.userId
      delete order.userPhoneNumber
    })
    const { isRequestSuccessful } = await this.api.putItem('users', user.id, user)
    if (isRequestSuccessful) this.alert.success(Strings.UPLOAD_SUCCESSFUL)
    else this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
  }
}
