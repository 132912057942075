import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AlertService } from 'src/app/services/alertService/alert.service'
import { StateService } from 'src/app/services/stateService/state.service'
import { APIService } from 'src/app/services/apiService/api.service'
import { StorageService } from 'src/app/services/storageService/storage.service'
import { IdService } from 'src/app/services/idService/id.service'

import ItemTypes from 'src/app/dataTypes/itemType'
import EditorMode from '../../dataTypes/editorMode'
import Strings from '../../dataTypes/strings'
import Url from '../../../../../common/util/url'

@Component({
	selector: 'app-cloth-editor',
	templateUrl: './accessory-editor.component.html',
	styleUrls: ['./accessory-editor.component.css']
})
export class AccessoryEditorComponent implements OnInit {
	EditorMode = EditorMode
	itemType = ItemTypes.ACCESSORIES
	mode: EditorMode
	itemId = ''
	item
	isLoading = true
	Strings = Strings
	constructor(
		private state: StateService,
		private alert: AlertService,
		private router: Router,
		private route: ActivatedRoute,
		private api: APIService,
		private storage: StorageService,
		private id: IdService) { }

	async ngOnInit() {
		this.route.paramMap.subscribe(async paramMap => {
			this.itemId = paramMap.get('id')
			this.mode = this.route.snapshot.data.mode
			if (this.mode === EditorMode.EDIT) this.item = await this.getItem()
			else this.item = this.newItem()
			this.isLoading = false
		})
	}

	private async getItem() {
		this.isLoading = true
		let item = this.state[this.itemType].find(item => item.id === this.itemId)
		if (!item) {
			const { isRequestSuccessful, item: i } = await this.api.getItem(this.itemType, this.itemId)
			item = i
			if (!isRequestSuccessful) {
				this.alert.error(Strings.ERROR_CONNECTING_TO_SERVER)
				this.router.navigate(['../../'])
			}
		}
		return JSON.parse(JSON.stringify(item))
	}

	private newItem() {
		return {
			id: this.id.generateItemId(this.itemType),
			name: "",
			price: 0,
			priceBeforeOffer: 0,
			imageUrl: "",
		}
	}

	onImgSelected(formEvent) {
		const selectedFile = formEvent.target.files[0]
		const fileReader = new FileReader()
		fileReader.onload = (readerEvent) => {
			this.item.imageUrl = readerEvent.target.result as string
		}
		fileReader.readAsDataURL(selectedFile)
	}

	async putItem() {
		for (const key in this.item) {
			if (this.item.hasOwnProperty(key) && (this.item[key] === null || this.item[key] === undefined || this.item[key] === '')) {
				if(key === 'priceBeforeOffer') continue
				this.alert.error(key === "imageUrl" ? Strings.ERROR_MUST_HAVE_IMAGE : Strings.ERROR_NO_EMPTY_VALUES)
				return
			}
		}
		this.item.price = Number(this.item.price)
		this.item.priceBeforeOffer = this.item.priceBeforeOffer ? Number(this.item.priceBeforeOffer) : this.item.priceBeforeOffer
		if (!Url.isValidURL(this.item.imageUrl)) {
			this.alert.info(Strings.UPLOADING_IMAGE)
			const { isUploadSuccessful, imageUrl } = await this.storage.uploadItemImage(this.itemType, this.item.id, this.item.imageUrl)
			if (!isUploadSuccessful) {
				this.alert.error(Strings.ERROR_UPLOADING_IMAGE)
				return
			}
			this.alert.success(Strings.IMAGE_UPLOAD_SUCCESSFUL)
			this.item.imageUrl = imageUrl
		}
		this.alert.info(Strings.UPLOADING)
		const { isRequestSuccessful, id } = await this.api.putItem(this.itemType, this.item.id, this.item)
		if (!isRequestSuccessful) this.alert.error(Strings.ERROR_UPLOADING)
		else {
			if (this.mode === EditorMode.NEW) this.state[this.itemType].push({ ...this.item, id })
			else Object.assign(this.state[this.itemType].find(item => item.id === this.item.id), this.item)
			this.alert.success(Strings.UPLOAD_SUCCESSFUL)
			this.router.navigateByUrl(this.itemType)
		}
	}
}