<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>

<main *ngIf="!isLoading">
    <div class="actions">
        <button [routerLink]="'../edit/' + item.id"><img src="../../../assets/edit.svg"> {{Strings.EDIT}}</button>
        <button (click)="deleteItem()"><img src="../../../assets/delete.svg"> {{Strings.DELETE}}</button>
    </div>
    <div class="data">
        <img [src]="item.imageUrl">
        <div>
            <p><strong>{{Strings.MODEL_CODE}}: </strong>{{item.code}}</p>
            <p><strong>{{Strings.EXTRA_PRICE}}: </strong>{{item.price}} {{Strings.SAUDI_RIYAL}}</p>
            <p><strong>{{Strings.PRICE_BEFORE_OFFER}}: </strong>{{item.priceBeforeOffer}} {{Strings.SAUDI_RIYAL}}</p>
        </div>
    </div>
</main>