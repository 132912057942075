<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>

<main *ngIf="!isLoading">
    <label for="name">{{Strings.NAME}}: </label>
    <input id="name" type="text" [value]="item.name" (input)="item.name=$event.target.value" required>

    <label for="type">{{Strings.TYPE}}: </label>
    <input id="type" type="text" [value]="item.type" (input)="item.type=$event.target.value" required>

    <label for="classification">{{Strings.CLASSIFICATION}}: </label>
    <select id="classification" [value]="item.classification" (input)="item.classification=$event.target.value" required>
        <option value="REGULAR">{{Strings.CLASSIFICATIONS.REGULAR}}</option>
        <option value="OCCASION">{{Strings.CLASSIFICATIONS.OCCASION}}</option>
    </select>

    <label for="description">{{Strings.DESCRIPTION}}: </label>
    <textarea id="description" rows="3" cols="50" [value]="item.description" (input)="item.description=$event.target.value" required>
    </textarea>

    <label for="price">{{Strings.PRICE}}: </label>
    <input id="price" type="number" [value]="item.price" (input)="item.price=$event.target.value" min="0" required>

    <label for="priceBeforeOffer">{{Strings.PRICE_BEFORE_OFFER}}: </label>
    <input id="priceBeforeOffer" type="number" [value]="item.priceBeforeOffer" (input)="item.priceBeforeOffer=$event.target.value" min="0" required>

    <label for="rating">{{Strings.RATING}} {{Strings.OUT_OF_10}}: </label>
    <input id="rating" type="number" [value]="item.rating" (input)="item.rating=$event.target.value" min="0" max="5" required>

    <label for="countryOfOrigin">{{Strings.COUNTRY}}: </label>
    <input id="countryOfOrigin" type="text" [value]="item.countryOfOrigin" (input)="item.countryOfOrigin=$event.target.value" required>

    <label for="imageUrl">{{Strings.IMAGE}}: </label>
    <input id="imageUrl" type="file" accept=".jpg, .jpeg, .png" (input)="onImgSelected($event)" required>
    <div></div>
    <img id="uploaded-img" [src]="item.imageUrl">

    <label for="availableColors">{{Strings.AVAILABLE_COLORS}}: </label>
    <span id="availableColors">
        <span class="available-color" *ngFor="let color of item.availableColors; index as i;">
            <input class="color" type="color" [value]="item.availableColors[i]" (change)=" item.availableColors[i]=$event.target.value" required>
            <span dir="ltr">{{item.availableColors[i]}}</span>
            <span class="delete" (click)="deleteColor(i)">&times;</span>
        </span>
        <img id="add-color" src="../../../assets/add.svg" (click)="item.availableColors.push('#FFFFFF')">
    </span>

    <button (click)="putItem()">
        {{this.mode === EditorMode.EDIT ? Strings.EDIT_ITEM[itemType] : Strings.ADD_ITEM[itemType]}}
    </button>
</main>