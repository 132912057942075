<!-- Full Screen Messages -->
<div class="full-screen-msg" *ngIf="isLoading">
    <p>{{Strings.LOADING}}</p>
</div>
<div class="full-screen-msg" *ngIf="!isLoading && isError">
    <p>{{Strings.ERROR_CONNECTING_TO_SERVER}}</p>
</div>
<div class="full-screen-msg" *ngIf="!isLoading && !isError && !state[itemType].length">
    <p>{{Strings.NOT_FOUND_IN_DATABASE[itemType]}}</p>
</div>

<!-- Items Cards List -->
<main class="cards-container" *ngIf="!isLoading && !isError && state[itemType].length">
    <div class="card" *ngFor="let item of stateItems; index as i; trackBy: itemTracker;">
        <div class="actions">
            <img [routerLink]="item.id" src="../../../assets/read-more.svg">
            <img [routerLink]="'edit/' + item.id" src="../../../assets/edit.svg">
            <img (click)="deleteItem(item.id)" src="../../../assets/delete.svg">
        </div>
        <div class="content">
            <div class="details">
                <p class="phone" dir="ltr">{{item.phoneNumber}}</p>
                <p>{{Strings.NUM_OF_PEOPLE}}: {{item.people ? item.people.length : 0}}</p>
            </div>
        </div>
    </div>
</main>

<!-- Floating Action Button -->
<button class="fab" routerLink="new">
    <img src="../../../assets/add.svg">
</button>