<div class="msg" *ngIf="isLoading && !isError">
    <p>{{Strings.LOADING}}</p>
</div>

<div class="msg" *ngIf="!isLoading && isError">
    <p>{{Strings.ERROR_CONNECTING_TO_SERVER}}</p>
</div>

<main *ngIf="!isLoading && !isError">
    <h1>{{Strings.ORDERS_NOT_DONE}}</h1>
    <div class="list">
        <div class="order" *ngFor="let order of uncompletedOrders; index as i" (click)="router.navigate(['orders/'+order.id])">
            <p><strong>{{Strings.PHONE}}:</strong>&nbsp;<wbr><span dir="ltr">{{order.userPhoneNumber}}</span></p>
            <p>
                <strong>{{Strings.ORDER_STATUS}}:</strong>&nbsp;<wbr>
                <span class="status-pending">{{Strings.PENDING}}</span>
            </p>
            <p><strong>{{Strings.ORDER_DATE}}:</strong> {{arabicDate(order.orderDate)}}</p>
            <p><strong>{{Strings.DELIVERY_DATE}}:</strong> {{arabicDate(order.deliveryDate)}}</p>
            <p><strong>{{Strings.TOTAL_PRICE}}:</strong> {{order.totalPrice}} {{Strings.SAUDI_RIYAL}}</p>
        </div>
    </div>

    <p class="no-orders" *ngIf="!uncompletedOrders?.length">
        {{Strings.NO_ORDERS}}
    </p>

    <hr>

    <h1>{{Strings.ORDERS_DONE}}</h1>
    <div class="list">
        <div class="order" *ngFor="let order of completedOrders; index as i" (click)="router.navigate(['orders/'+order.id])">
            <p><strong>{{Strings.PHONE}}:</strong>&nbsp;<wbr><span dir="ltr">{{order.userPhoneNumber}}</span></p>
            <p>
                <strong>{{Strings.ORDER_STATUS}}:</strong>&nbsp;<wbr>
                <span class="status-done">{{Strings.DELIVERED}}</span>
            </p>
            <p><strong>{{Strings.ORDER_DATE}}:</strong> {{arabicDate(order.orderDate)}}</p>
            <p><strong>{{Strings.DELIVERY_DATE}}:</strong> {{arabicDate(order.deliveryDate)}}</p>
            <p><strong>{{Strings.TOTAL_PRICE}}:</strong> {{order.totalPrice}} {{Strings.SAUDI_RIYAL}}</p>
        </div>
    </div>

    <p class="no-orders" *ngIf="!completedOrders?.length">
        {{Strings.NO_ORDERS}}
    </p>
</main>